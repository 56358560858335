<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="'WhatsApp'" :subtitle="$t('generic-str.send')" />
    <div class="page-content container-fluid">
      <div class="row">
        <!-- Left column -->
        <div class="col-lg-7">
          <div class="card card-tabs">
            <div class="card-header">
              <div class="card-header-left">
                <div class="flex-row" v-if="whatsNumber.name">
                  <div>
                    <div class="card-header-photo"></div>
                  </div>
                  <div style="margin-left: 10px;" class="c-point" v-b-toggle.sidebar-right-numbers>
                    <div class="c-pointer info-whatsapp-button">
                      <span class="card-header-name">{{ whatsNumber.name }}</span>
                      <span class="card-header-number">{{ whatsNumber.phone_number | maskPhone }}</span>
                      <span class="material-symbols-outlined card-header-sync">sync</span>
                    </div>
                    <div class="card-header-number-text">
                      Número WhatsApp Business
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-header-right" v-b-toggle.sidebar-right-template>
                <div>
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2330_41368)">
                      <path
                        d="M7.15039 21H11.1504C11.1504 22.1 10.2504 23 9.15039 23C8.05039 23 7.15039 22.1 7.15039 21ZM5.15039 20H13.1504V18H5.15039V20ZM16.6504 10.5C16.6504 14.32 13.9904 16.36 12.8804 17H5.42039C4.31039 16.36 1.65039 14.32 1.65039 10.5C1.65039 6.36 5.01039 3 9.15039 3C13.2904 3 16.6504 6.36 16.6504 10.5ZM14.6504 10.5C14.6504 7.47 12.1804 5 9.15039 5C6.12039 5 3.65039 7.47 3.65039 10.5C3.65039 12.97 5.14039 14.39 6.00039 15H12.3004C13.1604 14.39 14.6504 12.97 14.6504 10.5ZM21.5204 8.37L20.1504 9L21.5204 9.63L22.1504 11L22.7804 9.63L24.1504 9L22.7804 8.37L22.1504 7L21.5204 8.37ZM19.1504 7L20.0904 4.94L22.1504 4L20.0904 3.06L19.1504 1L18.2104 3.06L16.1504 4L18.2104 4.94L19.1504 7Z"
                        id="light-svg" fill="#16CD08" />
                    </g>
                    <defs>
                      <clipPath id="clip0_2330_41368">
                        <rect width="24" height="24" fill="white" transform="translate(0.900391)" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <span>Selecionar template</span>
              </div>
            </div>
            <div class="card-body">
              <div class="form-body">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="tab-content">
                      <div class="tab-pane active" id="simple">
                        <div class="form-group row">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                            <span class="material-symbols-outlined info-icons">campaign</span>
                          </div>
                          <div class="col-lg-10 col-sm-10 col-md-10 col-9">
                            <input type="text" class="form-control form-control-send" placeholder="Nome da campanha"
                              v-model="form.name">
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                            <span class="material-symbols-outlined info-icons">supervisor_account</span>
                          </div>
                          <div class="col-lg-10 col-sm-10 col-md-10 col-9">
                            <div class="row">
                              <div class="col-sm-7 relative tags-container" style="padding-right: 5px;"
                                v-if="selectedGroups.length == 0 && selectedContacts.length == 0">
                                <vue-tags-input v-model="form.to" :class="'form-control form-control-send'"
                                  :tags="form.tags" @tags-changed="(newTags) => (form.tags = newTags)"
                                  :placeholder="'Ex: (34) 44444-4444'" :separators="[',', ';', ' ']"
                                  :add-on-key="[13, ',', ';', ' ']" />
                                <span
                                  class="material-symbols-outlined span-close c-pointer close-input hide">close</span>
                              </div>
                              <div class="col-sm-7 no-margin tags-wrapper"
                                :class="{ 'hide': selectedGroups.length == 0 && selectedContacts.length == 0 }"
                                style="padding-right: 15px;">
                                <ul class="tabs-box">
                                  <li class="tab" v-for="(group, i) in selectedGroups" :key="i">
                                    <span>{{ group.name }}</span>
                                    <span class="material-symbols-outlined span-close"
                                      @click="removeGroup(i)">close</span>
                                  </li>
                                  <li class="tab" v-for="(contact, i) in selectedContacts" :key="i">
                                    <span>
                                      <template v-if="contact.mobile_number">
                                        {{ contact.mobile_number | maskPhone }}
                                      </template>
                                      <template v-if="contact.phone">
                                        {{ contact.phone | maskPhone }}
                                      </template>
                                    </span>
                                    <span class="material-symbols-outlined span-close"
                                      @click="removeContact(i)">close</span>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-sm-4 no-margin no-padding icon-sends">
                                <span class="material-symbols-outlined span-close c-pointer"
                                  v-b-toggle.sidebar-right-contact
                                  v-tooltip.top="'Enviar por Contatos'">contact_page</span>
                                <span class="material-symbols-outlined span-close c-pointer"
                                  v-b-toggle.sidebar-right-group v-tooltip.top="'Enviar por Grupos'">group</span>
                                <span class="material-symbols-outlined span-close c-pointer"
                                  v-b-toggle.sidebar-right-import
                                  v-tooltip.top="'Enviar por Arquivo'">file_upload</span>
                                <span class="material-symbols-outlined span-close c-pointer add-circle hide"
                                  @click="addInput()">add_circle</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row" v-if="this.$store.state.plans.omniPlan">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                            <span class="material-symbols-outlined info-icons">send_time_extension</span>
                          </div>
                          <div class="col-lg-10 col-sm-10 col-md-10 col-9">
                            <span>Fidelização por agente</span>
                            <select class="form-control select-agent" v-model="form.agent_id">
                              <option value="" selected>Escolha um agente</option>
                              <option v-for="agent in agents" :key="agent.id" :value="agent.id">{{ agent.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group row" v-if="form.template && form.template.type == 'MPM'">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                            <span class="material-symbols-outlined info-icons">storefront</span>
                          </div>
                          <div class="col-lg-10 col-sm-10 col-md-10 col-9">
                            <span>ID do Produto em destaque</span>
                            <input class="form-control form-control-send m-top-10"
                              v-model="form.template.button_parameter" />
                          </div>
                        </div>
                        <div class="form-group row" v-if="form.template && form.template.type == 'AUTH'">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                            <span class="material-symbols-outlined info-icons">key</span>
                          </div>
                          <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <span>Código de verificação</span>
                            <input class="form-control form-control-send m-top-10" @change="changeCodeText"
                              v-model="form.template.button_parameter" />
                          </div>
                        </div>
                        <div class="form-group row" v-if="form.template && form.template.type == 'MPM'">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                            <span class="material-symbols-outlined info-icons">list</span>
                          </div>
                          <div class="col-lg-10 col-sm-10 col-md-10 col-9">
                            <div class="d-flex m-bottom-10"><span>Sessões</span><span @click="addSession"
                                class="material-symbols-outlined btn-add-session">add_circle</span></div>
                            <div class="card session-products" v-for="(session, i) in sections" :key="i">
                              <div class="row">
                                <div class="col-10">
                                  <h5 class="title-session-h5">Título da sessão</h5>
                                  <input class="form-control form-session-title" v-model="session.title"
                                    placeholder="Digite o título da sessão" />
                                </div>
                                <div class="col-2 session-arrows">
                                  <span class="material-symbols-outlined btn-delete-session" @click="removeSession(i)"
                                    v-if="sections.length > 1">
                                    delete
                                  </span>
                                  <span class="material-symbols-outlined" @click="showSession(session)"
                                    v-if="!session.isHide">
                                    keyboard_arrow_right
                                  </span>
                                  <span class="material-symbols-outlined" @click="showSession(session)" v-else>
                                    keyboard_arrow_down
                                  </span>
                                </div>
                              </div>
                              <div class="row m-0" v-if="session.isHide">
                                <hr class="hr-top-divider">
                                <h5 class="title-session-h5">Produtos</h5>
                                <div class="card session-products" v-for="(product, j) in session.product_items"
                                  :key="j" style="width: 100%;padding-bottom: 8px;">
                                  <div class="row">
                                    <div class="col-10">
                                      <h5 class="title-session-h5">ID do produto</h5>
                                      <input class="form-control form-session-title" placeholder="ID do produto"
                                        v-model="product.product_retailer_id" />
                                    </div>
                                    <div class="col-2 session-arrows">
                                      <span class="material-symbols-outlined btn-delete-session"
                                        @click="removeProduct(session.product_items, j)"
                                        v-if="session.product_items.length > 1">
                                        delete
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="btn-add-product" @click="addProduct(session)"><span
                                      class="material-symbols-outlined btn-add-session"
                                      style="font-size: 18px;margin-top: 4px;">add_circle</span> <span
                                      class="btn-add-session" style="font-size: 15px;">Adicionar</span></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row" v-if="form.template && form.template.type == 'CATALOG'">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                            <span class="material-symbols-outlined info-icons">storefront</span>
                          </div>
                          <div class="col-lg-10 col-sm-10 col-md-10 col-9 tags-container-prod">
                            <span>ID dos Produtos</span>
                            <vue-tags-input v-model="form.template.button_parameter2"
                              :class="'form-control form-control-send m-top-10'" :tags="form.template.button_parameter"
                              @tags-changed="(newTags) => (form.template.button_parameter = newTags)"
                              :placeholder="'Ex: r5fu6uja4y'" :separators="[',', ';', ' ']"
                              :add-on-key="[13, ',', ';', ' ']" />
                          </div>
                        </div>
                        <div class="form-group row"
                          v-if="form.template && form.template.limited != undefined && form.template.limited.has_expiration">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                            <span class="material-symbols-outlined info-icons">timer</span>
                          </div>
                          <div class="col-lg-6 col-sm-6 col-md-6 col-9">
                            <span>Tempo de expiração (em segundos)</span>
                            <input type="number" class="form-control form-control-send m-top-10" min="1"
                              v-model="form.template.expiration_time_ms" />
                          </div>
                        </div>
                        <div class="form-group row"
                          v-if="form.template && (form.template.type == 'CUPOM' || form.template.type == 'OFFER')">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                            <span class="material-symbols-outlined info-icons">percent</span>
                          </div>
                          <div class="col-lg-6 col-sm-6 col-md-6 col-9">
                            <span>Código do cupom</span>
                            <input class="form-control form-control-send m-top-10"
                              v-model="form.template.button_parameter" />
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="divider-form"></div>
                        </div>
                        <div class="form-group row" v-for="(example, i) in form.body_parameters" :key="i">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                            <span class="material-symbols-outlined info-icons info-var">data_object</span>
                          </div>
                          <div class="col-6">
                            <input type="text" v-model="form.body_parameters[i]" class="form-control form-control-send"
                              :placeholder="'Variável para {{' + (i + 1) + '}}'">
                          </div>
                        </div>

                        <div class="form-group row" style="margin-bottom: 0;">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                            <span class="material-symbols-outlined info-icons info-var-tip">data_object</span>
                          </div>
                          <div class="col-9 info-var-tip-text">
                            Variáveis do sistema que podem ser usadas:
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                          </div>
                          <div class="col-9 info-var-tip-text">
                            <div class="row" style="margin-bottom: 10px;">
                              <div class="col-3">
                                <span class="var-name-span">{first_name}</span>
                              </div>
                              <div class="col-3">
                                <span class="var-name-span">{last_name}</span>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-4">
                                <span class="var-name-span">{mobile_number}</span>
                              </div>
                              <div class="col-3">
                                <span class="var-name-span">{email}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row" style="margin-bottom: 0;">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                          </div>
                          <div class="col-9 btn-files">
                            <span class="material-symbols-outlined info-attach_file" v-tooltip.top="`Documento`"
                              @click="callUploadFile(), typeFile = 'application/pdf', typeUpload = 'document'"
                              v-if="this.form.template != null && this.form.template.header != null && this.form.template.header.type === 'DOCUMENT'">attach_file</span>
                            <div class="sr-only">
                              <input type="file" ref="currentFilePdf" accept="application/pdf"
                                @change="getLocalFilePdf('DOCUMENT')" />
                            </div>
                            <span
                              v-if="this.form.template != null && this.form.template.header != null && this.form.template.header.type === 'VIDEO'"
                              class="material-symbols-outlined info-attach_file" v-tooltip.top="`Vídeo`"
                              @click="callUploadFile(), typeFile = 'video/mp4', typeUpload = 'video'">movie</span>
                            <div class="sr-only">
                              <input type="file" ref="currentFile" accept="video/mp4" @change="getLocalFile('VIDEO')" />
                            </div>
                            <span
                              v-if="this.form.template != null && this.form.template.header != null && this.form.template.header.type === 'IMAGE'"
                              class="material-symbols-outlined info-photo"
                              @click="callUploadFile(), typeFile = 'image/*', typeUpload = 'image'"
                              v-tooltip.top="`Imagem`">photo</span>
                          </div>
                        </div>
                        <div class="form-group row text-right">
                          <div class="col-sm-12">
                            <div>
                              <div class="right-items">
                                <button class="send-button send-button-outline hide">Enviar teste</button>
                                <y-date-picker :btnLabel="'Agendar envio'" :type="'button'" :iconClass="'calendarIcon'"
                                  :options="optionCalendar" :format="'DD/MM/YYYY HH:mm'"
                                  @change="changeSchedule"></y-date-picker>
                                <button class="btn send-button" type="button" :class="{
                                  'qt-loader qt-loader-mini qt-loader-right': isSending
                                }" :disabled="isSending || importing" @click="requestSend()">Enviar</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- -->
                    <div class="form-group" v-if="preview">
                      <div class="row">
                        <div class="col-lg-12">
                          <label for="sms-body">Preview</label>
                        </div>
                        <div class="col-lg-12">
                          <div class="whats-container">
                            <div class="whats-preview">
                              <p class="whats-preview-header" v-if="form.header_type === 'TEXT'">
                                {{ form.header.body }}
                              </p>
                              <div class="relative" v-else-if="form.header_type === 'IMAGE'">
                                <img id="img-preview" :src="`${header.body}`" />
                              </div>
                              <p class="whats-preview-body" v-if="preview.body">
                                {{ preview.body.text }}
                              </p>
                              <p class="whats-preview-footer" v-if="preview.footer">
                                {{ preview.footer.body }}
                              </p>
                            </div>
                            <div v-if="preview.buttons">
                              <template v-if="preview.buttons.call_to_action_dial_phone.text">
                                <button type="button" class="btn-link-preview">
                                  <i class="fas fa-phone"></i> {{ preview.buttons.call_to_action_dial_phone.text }}
                                </button>
                              </template>
                              <template v-else-if="preview.buttons.call_to_action_website.text">
                                <button type="button" class="btn-link-preview">
                                  <i class="fas fa-link"></i> {{ preview.buttons.call_to_action_website.text }}
                                </button>
                              </template>
                              <template v-else-if="preview.buttons.preview.buttons.quick_replies">
                                <template v-for="(reply, i) in preview.buttons.preview.buttons.quick_replies">
                                  <button type="button" class="btn-link-preview" :key="i">
                                    {{ reply }}
                                  </button>
                                </template>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/Left column -->
        <!-- <div class="col-lg preview-div"> -->
        <whatsapp-send-form :recipients="form.client_number" :scheduled="scheduled" :extras="true"
          :template="form.template" @success="success()" />
        <!-- @success="$router.push('/whatsapp/rebranding/jv/send')" /> -->
        <!-- </div> -->
      </div>
    </div>
    <b-sidebar class="b-sidebar-modal" id="sidebar-right-group" :title="'Grupos'" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <p>Grupos</p>
        <span v-b-toggle.sidebar-right-group class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div class="col desc-column">
        <p>
          Simplifique suas campanhas com nossa ferramenta de seleção de grupos de contatos. Alcance o público certo, no
          momento certo, com facilidade e eficiência, garantindo o sucesso de suas campanhas de marketing.
        </p>
      </div>
      <form class="add-prod-form col mb-0">
        <!-- Name -->
        <div class="input-group">
          <div class="search-input relative">
            <input placeholder="Buscar" class="form-control" ref="searchInput" />
            <span class="material-symbols-outlined c-pointer" @click="searchGroup">search</span>
          </div>
        </div>
        <router-link to="/contacts/groups">
          <div class="input-group">
            <div class="header-options">
              <div class="header-options-title" style="width: 55%;">
                <span>Meus grupos</span>
              </div>
              <div class="header-options-buttons c-pointer">
                <span class="material-symbols-outlined">settings</span>
                <h4>Gerenciar grupos</h4>
              </div>
            </div>
          </div>
        </router-link>
        <div class="input-group">
          <div class="text-center py-5 w100" v-if="!fetchedGroups">
            <img src="assets/img/loading.gif">
          </div>
          <div v-if="fetchedGroups" class="w100">
            <div class="row row-list" v-for="(group, i) in groups" :key="group.id">
              <div class="col-sm-1">
                <div class="custom-control custom-checkbox">
                  <input v-model="groupsToAdd" type="checkbox" class="custom-control-input" :value="group"
                    :id="'group-check-' + i" />
                  <label class="custom-control-label" :for="'group-check-' + i"></label>
                </div>
              </div>
              <div class="col-sm-10">
                <div class="flex">
                  <span class="material-symbols-outlined">group</span>
                  <span>{{ group.name }}</span>
                </div>
              </div>
              <div class="col-sm-1">

              </div>
            </div>
            <div class="row">
              <pagination v-model="currentPageGroup" :total-pages="pagesGroup" @change="getGroups" />
            </div>
          </div>
        </div>
        <div class="input-group text-right flex-c modal-footer">
          <button type="button" v-b-toggle.sidebar-right-group class="btn btn-yup btn-add align-r"
            @click="applyGroups()">Aplicar</button>
        </div>
      </form>
    </b-sidebar>
    <b-sidebar class="b-sidebar-modal" id="sidebar-right-contact" :title="'Contatos'" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <p>Contatos</p>
        <span v-b-toggle.sidebar-right-contact class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div class="col desc-column">
        <p>
          Simplifique suas campanhas com nossa ferramenta de seleção de contatos. Alcance o público certo, no momento
          certo, com facilidade e eficiência, garantindo o sucesso de suas campanhas de marketing.
        </p>
      </div>
      <form class="add-prod-form col mb-0">
        <!-- Name -->
        <div class="input-group">
          <div class="search-input relative">
            <input placeholder="Buscar" class="form-control" v-model="formContact.q" />
            <span class="material-symbols-outlined c-pointer" @click="searchContacts"
              ref="searchInputContact">search</span>
          </div>
        </div>
        <div class="input-group">
          <div class="text-center py-5 w100" v-if="!fetchedContacts">
            <img src="assets/img/loading.gif">
          </div>
          <div v-if="fetchedContacts" class="w100">
            <div class="row row-list" v-for="(contact, i) in contacts" :key="contact.id">
              <div class="col-sm-1">
                <div class="custom-control custom-checkbox" style="margin-top: -9px;">
                  <input v-model="contactsToAdd" type="checkbox" class="custom-control-input" :value="contact"
                    :id="'contact-check-' + i" />
                  <label class="custom-control-label" :for="'contact-check-' + i"></label>
                </div>
              </div>
              <div class="col-sm-7" style="padding-left: 0px;">
                <div class="flex">
                  <div class="profile-photo">
                    <figure :style="{ backgroundColor: $strToColor(contact.first_name + '' + contact.last_name) }">
                      <div class="fallback-image">
                        {{ initials(contact.first_name, contact.last_name) }}
                      </div>
                    </figure>
                  </div>
                  <span class="contact-name">
                    {{ contact.first_name }}
                  </span>
                </div>
              </div>
              <div class="col-sm-4">
                <span class="contact-name contact-phone">
                  <template v-if="contact.mobile_number">
                    {{ contact.mobile_number | maskPhone }}
                  </template>
                  <template v-if="contact.phone">
                    {{ contact.phone | maskPhone }}
                  </template>
                </span>
              </div>
            </div>
            <div class="row">
              <pagination v-model="currentPage" :total-pages="pages" @change="getContacts" />
            </div>
          </div>
        </div>
        <div class="input-group text-right flex-c modal-footer">
          <button type="button" v-b-toggle.sidebar-right-contact class="btn btn-yup btn-add align-r"
            @click="applyContacts()">Aplicar</button>
        </div>
      </form>
    </b-sidebar>
    <b-sidebar class="b-sidebar-modal" id="sidebar-right-template" :title="'Templates'" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <p>Templates</p>
        <span v-b-toggle.sidebar-right-template class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div class="col desc-column">
        <p>
          Simplifique suas campanhas com templates exclusivos. Alcance o público certo, no momento certo, com facilidade
          e
          eficiência, garantindo o sucesso de suas campanhas de marketing.
        </p>
      </div>
      <form class="add-prod-form col mb-0" @submit.prevent="fetchTemplates(1)">
        <!-- Name -->
        <div class="input-group">
          <div class="search-input relative">
            <input placeholder="Buscar" class="form-control" ref="searchTemplatesInput" v-model="searchQuery" />
            <span class="material-symbols-outlined c-pointer" @click="fetchTemplates(1)">search</span>
          </div>
        </div>
        <div class="input-group">
          <div class="header-options">
            <div class="header-options-title" style="width: 50%;">
            </div>
            <div class="header-options-buttons header-options-buttons-template">
              <h4 style="color: #697077;">Ordenar por (padrão) </h4>
              <svg width="24" height="24" class="c-pointer" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M17 20L21 16M3 4H16H3ZM3 8H12H3ZM3 12H12H3ZM17 8V20V8ZM17 20L13 16L17 20Z" stroke="#697077"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
        <div v-if="fetchedTemplates">
          <div class="accordion accordion-wpp-template" role="tablist">
            <ButtonComponent class="mr-2" :variant="templateCategory === '' ? 'primary' : 'secondary'"
              @click="changeCategory('')">
              Todos
            </ButtonComponent>
            <ButtonComponent class="mr-2" :variant="templateCategory === 'MARKETING' ? 'primary' : 'secondary'"
              @click="changeCategory('MARKETING')">
              <span class="material-symbols-outlined">campaign</span>
            </ButtonComponent>
            <ButtonComponent class="mr-2" :variant="templateCategory === 'UTILITY' ? 'primary' : 'secondary'"
              @click="changeCategory('UTILITY')">
              <span class="material-symbols-outlined">notifications</span>
            </ButtonComponent>
            <ButtonComponent :variant="templateCategory === 'AUTHENTICATION' ? 'primary' : 'secondary'"
              @click="changeCategory('AUTHENTICATION')">
              <span class="material-symbols-outlined">key</span>
            </ButtonComponent>
          </div>
        </div>
        <div class="text-center py-5 w100" v-if="!fetchedTemplates">
          <img src="assets/img/loading.gif">
        </div>
        <div v-if="fetchedTemplates && templates.length == 0" class="text-center w-100 no-item">
          <br><br>
          <span class="material-symbols-outlined d-block">palette</span>
          <p class="d-block">Nenhum template encontrado</p>
        </div>
        <div class="input-group" v-for="(templ, i) in templates" :key="templ.id">
          <div class="row row-list row-template-list">
            <div class="col-sm-1">
              <input v-model="form.template" type="radio" :value="templ" v-b-toggle.sidebar-right-template
                @change="changeTemplate(templ)" :id="'contact-template-check-' + i" />
            </div>
            <div class="col-sm-9">
              <div class="flex">
                <span>{{ templ.name }}</span>
              </div>
            </div>
            <div class="col-sm-2">
              <span class="material-symbols-outlined icon-show-hide c-pointer" @click="showTemplate(templ)"
                v-if="!templ.show">visibility_off</span>
              <span class="material-symbols-outlined icon-show-hide c-pointer" @click="hideTemplate(templ)"
                v-if="templ.show">visibility</span>
            </div>
            <div class="col-sm-12 text-right">
              <span class="badge badge-light">
                {{ templ.category }}
              </span>
            </div>
          </div>
          <div class="row row-templates" v-if="templ.show" style="margin-bottom: 10px;">
            <div class="col-sm-12">
              <div class="card-template">
                <PreviewTemplate :template="templ" />
              </div>
            </div>
          </div>
        </div>
        <pagination v-model="templatePage" :total-pages="templatePages" @change="fetchTemplates" />
        <div class="input-group text-right flex-c modal-footer" v-if="templates.length">
          <button type="button" v-b-toggle.sidebar-right-template class="btn btn-yup btn-add align-r">Aplicar</button>
        </div>
      </form>

    </b-sidebar>
    <b-sidebar class="b-sidebar-modal" id="sidebar-right-import" :title="'Importar'" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <p>Importar contatos</p>
        <span v-b-toggle.sidebar-right-import class="material-symbols-outlined sidebar-close c-pointer">close</span>
      </div>
      <div class="col desc-column">
        <p>
          Faça upload de arquivos csv para importar contatos
        </p>
      </div>
      <form class="add-prod-form col mb-0">
        <!-- Name -->
        <div class="input-group">
          <div class="drop-item-upload">
            <img src="@/assets/csv_import.svg" v-if="!importing" />
            <div id="cont" data-pct="100" :class="{ 'hide': !importing }">
              <svg id="svg" width="200" height="200" viewPort="0 0 100 100" version="1.1"
                xmlns="http://www.w3.org/2000/svg">
                <circle r="90" cx="100" cy="100" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset="0">
                </circle>
                <circle id="bar" r="90" cx="100" cy="100" fill="transparent" stroke-dasharray="565.48"
                  stroke-dashoffset="0"></circle>
              </svg>
            </div>
            <h3>Clique ou arraste seu cvs aqui</h3>
            <button type="button" @click="openFileUpload">Selecionar Arquivos do seu dispositivo</button>
            <input ref="uploadedFile" type="file" accept=".csv" hidden @change="uploadFile" />
          </div>
          <div class="row csv-file" v-if="form.file">
            <div class="icon-div">
              <img src="assets/img/csv_icon.svg">
            </div>
            <div class="name-csv-div">
              <span>{{ form.file }}</span>
              <div class="csv-progress"></div>
            </div>
            <div class="name-csv-percent">100%</div>
          </div>
          <p class="drop-item-upload-p">
            Alguns formatos de dados, como nomes e números podem não ser reconhecidos. ler mais
          </p>
          <div>
            <a class="sample-area d-flex align-items-center" href="/exemplos/example.csv" download="example.csv"
              v-tooltip.top="$t('sms.send-msg.tabs.archives.lbl-tooltip-archive')">
              <!-- {{$t('generic-str.example')}} -->
              <span class="material-icons-outlined" style="margin-right: 5px;">file_download</span>&nbsp;&nbsp;Baixar
              arquivo de exemplo
            </a>
          </div>
        </div>
      </form>
    </b-sidebar>
    <b-sidebar class="b-sidebar-modal" id="sidebar-right-numbers" :title="'Números'" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <p>Números</p>
        <span v-b-toggle.sidebar-right-numbers class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div class="col desc-column">
        <p>
          Potencialize seu alcance e fortaleça seu relacionamento com o público-alvo de forma eficiente e personalizada,
          selecione abaixo o seu número para disparo de campanhas:
        </p>
      </div>
      <form class="add-prod-form col mb-0">
        <!-- Name -->
        <div class="input-group">
          <div class="search-input relative">
            <input placeholder="Buscar" class="form-control" />
            <span class="material-symbols-outlined c-pointer" @click="searchContacts"
              ref="searchInputContact">search</span>
          </div>
        </div>
        <div class="input-group">
          <div class="text-center py-5 w100" v-if="!fetchedContacts">
            <img src="assets/img/loading.gif">
          </div>
          <div v-if="whatsNumbers" class="w100">
            <div class="row row-list" v-for="(number, i) in whatsNumbers" :key="number.id">
              <div class="col-sm-1">
                <div>
                  <input v-model="whatsNumber" type="radio" :value="number" :id="'contact-check-' + i" />
                </div>
              </div>
              <div class="col-sm-7" style="padding-left: 0px;">
                <div class="flex">
                  <span class="contact-name">
                    {{ number.phone_number | maskPhone }}
                  </span>
                </div>
              </div>
              <div class="col-sm-4 text-right">
                <span class="contact-name contact-phone">
                  {{ number.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="input-group text-right flex-c modal-footer">
          <button type="button" v-b-toggle.sidebar-right-numbers class="btn btn-yup btn-add align-r"
            @click="changeNumber">Aplicar</button>
        </div>
      </form>
    </b-sidebar>
    <create-contact-modal id="create-contact"></create-contact-modal>
    <create-group-modal id="create-group"></create-group-modal>
    <group-send-modal id="group-send-modal"></group-send-modal>
    <contact-send-modal id="contact-send-modal"></contact-send-modal>
    <m-crop id="m-crop" />
    <UploadComponent :preview="true" ref="uploadMenu" :accept="typeFile" :maxFileSize="5" @uploaded="uploaded" />
  </div>
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue.css';
// @ is an alias to /src
import axios from '@/plugins/axios';
import WhatsAppService from '@/services/whatsapp.service';
import SmsService from '@/services/sms.service';
import Tab from '@/directives/Tab';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import WhatsappSendForm from '@/components/rebranding/whatsappj/WhatsappSendForm.vue';
import Utils from '@/models/utils';
import CreateContactModal from '@/components/contacts/CreateContactModal.vue';
import GroupSendModal from '@/components/groups/GroupSendModal.vue';
import ContactSendModal from '@/components/contacts/ContactSendModal.vue';
// import GroupTable from '@/components/groups/GroupTable.vue';
import CreateGroupModal from '@/components/groups/CreateGroupModal.vue';
// import TemplateInput from '@/components/whatsappj/TemplateInput.vue';
import Swal from 'sweetalert2';
import Pagination from '@/components/rebranding/pagination/Pagination.vue';
import $ from 'jquery';
import moment from 'moment';
import MCrop from '@/components/MCrop.vue';
import BucketService from '@/services/bucket.service';
import ContactService from '@/services/contact.service';
import VueTagsInput from '@johmun/vue-tags-input';
import YDatePicker from '@/components/DatePicker/YDatePicker.vue';
import UploadComponent from '@/components/ui/UploadComponent/Index.vue';
import PreviewTemplate from '@/components/rebranding/whatsappj/PreviewTemplate.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

const webApi = axios();

const GSM = /^[ -~\n]*$/;

export default {
  name: 'WhatsAppJavaSend',
  components: {
    PageHeader,
    // TemplateInput,
    Pagination,
    CreateContactModal,
    ContactSendModal,
    GroupSendModal,
    CreateGroupModal,
    MCrop,
    WhatsappSendForm,
    VueTagsInput,
    YDatePicker,
    UploadComponent,
    PreviewTemplate,
    ButtonComponent,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      url: '',
      templateCategory: '',
      typeFile: 'image/*',
      typeUpload: 'image',
      optionCalendar: {
        timePicker: true,
        singleDatePicker: true,
        autoApply: true,
        startDate: moment().format('DD/MM/YYYY'),
        drops: 'up',
        timePicker24Hour: true,
      },
      simpleNumber: null,
      fetchedGroups: false,
      fetchedTemplates: false,
      templates: [],
      whatsNumber: '',
      whatsNumbers: [],
      contactsToAdd: [],
      whatsapp_identification: '',
      lastWAI: '',
      template: {
      },
      templatePage: 1,
      templatePages: 1,
      bodyParams: [],
      breadcrumbLinks: {
        1: '/whatsapp/jv/reports/history',
        2: '/whatsapp/jv/send',
      },
      teste: '0',
      Toast: {},
      file: {},
      cropPreview: {},
      cropActive: {},
      client: {},
      shortCodes: [],
      addVars: false,
      hasVar: false,
      groups: [],
      isSending: false,
      fetchedContacts: false,
      contacts: [],
      files: [],
      groupsToAdd: [],
      selectedGroups: [],
      selectedContacts: [],
      selectedFiles: [],
      importing: false,
      uploadProgress: 0,
      vars: [],
      fileVariables: [],
      preview: null,
      scheduled: false,
      scheduledFor: '',
      sections: [
        {
          title: 'Popular Bundles',
          product_items: [
            {
              product_retailer_id: 'f5ts4jxsxo',
            },
          ],
        },
      ],
      form: {
        client_number: '',
        header_parameter: null,
        header_type: null,
        language_code: 'pt_BR',
        from: 'default',
        normalize: false,
        file: '',
        name: '',
        business_id: '',
        type: 'simple',
        schedule: false,
        template: null,
        contacts: [],
        groups: [],
        tags: [],
        channel_id: process.env.VUE_APP_WHATS_META,
      },
      options: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: true,
        minDate: moment(),
        locale: 'pt',
        keepInvalid: true,
      },
      unicode: false,
      replace: false,
      flash: false,
      segments: 1,
      submited: false,
      length: 0,
      page: 1,
      pages: 1,
      currentPage: 1,
      totalPages: 1,
      formContact: {
        page: 1,
      },
      pageGroup: 1,
      pagesGroup: 1,
      currentPageGroup: 1,
      totalPagesGroup: 1,
      agents: [],
      searchQuery: null,
      originalTemplates: [],
      formGroup: {
        page: 1,
      },
    };
  },
  computed: {
    headerType() {
      return this.template?.header?.type || null;
    },
    classes() {
      return ['wizard'];
    },
    isValid() {
      return this.form.business_id && true;
    },
    headerCurrentVideo() {
      if (this.headerType === 'VIDEO') {
        return this.headerVideo || this.template?.header?.['example_parameter'];
      }

      return null;
    },
    headerCurrentDocument() {
      if (this.headerType === 'DOCUMENT') {
        return this.headerDocument || this.template?.header?.['example_parameter'];
      }

      return null;
    },
    headerCurrentImage() {
      if (this.headerType === 'IMAGE') {
        return this.headerImage || this.template?.header?.['example_parameter'];
      }

      return null;
    },
    headerPreview() {
      return this.template?.header?.body?.replace(/\{\{1\}\}/, (param) => this.headerParam || param);
    },
    bodyPreview() {
      return this.template?.body?.text?.replace(/\{\{(\d+)\}\}/g, (param) => {
        const index = param.slice(2, -2) - 1;

        return this.bodyParams[index] || param;
      });
    },
    headerResults() {
      const result = this.template?.header?.body;

      return !result ? '' : result.replace(/\{\{1\}\}/, (param) => this.headerParam || '');
    },
    bodyResults() {
      return this.template?.body?.text?.replace(/\{\{(\d+)\}\}/g, (param) => {
        const index = param.slice(2, -2) - 1;

        return this.bodyParams[index] ?? null;
      });
    },
    footerBody() {
      return this.template?.footer?.body;
    },
  },
  created() {
    setInterval(() => {
      this.teste += ',1234';
    }, 1000);

    this.fetchClient();
    this.Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
    this.getWhatsNumbers();
    this.getGroups(1);
    this.getContacts(1);
    setTimeout(() => {
      this.changeProgress(1);
    }, 100);
    setTimeout(() => {
      this.changeProgress(1);
    }, 3000);
    this.fetchAgents();

    if (this.$route.params.contacts) {
      this.contacts = this.$route.params.contacts;
      this.contacts.forEach((contact) => {
        this.form.tags.push({
          text: contact.mobile_number,
          id: contact.id,
          type: 'contact',
        });
      });
    }
    if (this.$route.params.groups) {
      this.groups = this.$route.params.groups;
      this.groups.forEach((group) => {
        this.form.tags.push({
          text: group.name,
          id: group.id,
          type: 'group',
        });
      });
    }
  },
  methods: {
    callUploadFile() {
      this.$refs.uploadMenu.toggle();
    },
    uploaded(response) {
      console.log('Salvo', response);
      console.log('typeUpload', this.typeUpload);
      switch (this.typeUpload) {
        case 'image':
          this.form.template.headerImage = {
            url: response.data.path,
          };
          this.form.template.header.example_parameter = response.data.path;
          break;
        case 'video':
          this.form.template.header.example_parameter = response.data.path;
          this.form.template.headerVideo = {
            url: response.data.path,
          };
          break;
        case 'document':
          this.form.template.header.example_parameter = response.data.path;
          this.form.template.headerDocument = {
            url: response.data.path,
          };
          break;
        default:
          break;
      }
      this.$root.$emit('whatsapp.changeTemplate', this.form.template);
      this.url = response.data.path;
      this.$refs.uploadMenu.toggle();
    },
    removeSession(i) {
      this.sections.splice(i, 1);
    },
    removeProduct(session, i) {
      session.splice(i, 1);
    },
    addSession() {
      if (this.sections.length < 10) {
        this.sections.push(
          {
            title: 'Premium Packages',
            product_items: [
              {
                product_retailer_id: 'r5fu6uja4y',
              },
            ],
          },
        );
      } else {
        this.$toast.show({
          title: 'Erro',
          content: 'Você atingiu o limite máximo de sessões',
          type: 'danger',
        });
      }
    },
    addProduct(session) {
      let totalProds = 0;
      this.sections.forEach((session) => {
        totalProds += session.product_items.length;
      });
      if (totalProds < 30) {
        session.product_items.push(
          {
            product_retailer_id: 'r5fu6uja4y',
          },
        );
      } else {
        this.$toast.show({
          title: 'Erro',
          content: 'Você atingiu o limite máximo de produtos',
          type: 'danger',
        });
      }
    },
    showSession(session) {
      session.isHide = !session.isHide;
      this.$forceUpdate();
    },
    changeSchedule(date) {
      this.form.schedule = true;
      this.form.datetime = date;
      this.$forceUpdate();
    },
    fetchAgents() {
      webApi.get('omni/agents').then((response) => {
        this.agents = [];
        console.log('Agents');
        console.log(response);
        this.agents = response.data;
      }, (error) => {
      });
    },
    addTagFunc(tag) {
      console.log('Salvando tag');
      console.log(tag);
    },
    dragEvent() {
      /* eslint-disable */
      const tabsBox = document.querySelector('.tabs-box');
      if (tabsBox) {
        let isDragging = false;
        const dragging = (e) => {
          // console.log('dragging');
          if (!isDragging) return;
          tabsBox.classList.add('dragging');
          tabsBox.scrollLeft -= e.movementX;
        };

        const dragStop = () => {
          isDragging = false;
          tabsBox.classList.remove('dragging');
        }

        tabsBox.addEventListener('mousedown', () => isDragging = true);
        tabsBox.addEventListener('mousemove', dragging);
        document.addEventListener('mouseup', dragStop);
      }
    },
    searchTemplates() {
      const word = this.$refs.searchTemplatesInput.value;
      let result = null;
      this.fetchedTemplates = false;
      this.templates = [];
      WhatsAppService.getNewTemplates(this.whatsapp_identification).then((response) => {
        this.fetched = true;
        if (response && response.data && response.data.content) {
          response.data.content.forEach((template) => {
            if (template.status === 'APPROVED') {
              this.templates.push(template);
            }
          });
          result = this.templates.filter((item) => word.toLowerCase().split(' ').every((v) => item.name.toLowerCase().includes(v)));
          this.templates = result;
          this.originalTemplates = this.templates;
        }
        this.fetchedTemplates = true;
      }, (error) => {
        this.content = error;
      }).finally(() => {
        this.fetchedTemplates = true;
      });
    },
    addInput() {
      this.form.type = 'simple';

      this.form.contacts = [];
      this.form.groups = [];
      this.selectedGroups = [];
      this.selectedContacts = [];
    },
    changeProgress(val) {
      const circle = document.getElementById('bar');
      const cont = document.getElementById('cont');

      const r = circle.getAttribute('r');
      const c = Math.PI * (r * 2);

      if (val < 0) {
        val = 0;
      }
      if (val > 100) {
        val = 100;
      }

      const pct = ((100 - val) / 100) * c;

      circle.style = `stroke-dashoffset: ${pct}`;

      cont.setAttribute('data-pct', val);
    },
    searchContacts() {
      this.fetchedContacts = false;
      const word = this.$refs.searchInputContact.value;
      this.formContact.page = 1;
      ContactService.searchContacts(this.formContact, word).then(
        (response) => {
          this.fetchedContacts = true;
          this.contacts = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
          this.fetchedContacts = true;
        },
      );
    },
    searchGroup() {
      this.fetchedGroups = false;
      const word = this.$refs.searchInput.value;
      // console.log('Query: ', word);
      let result = null;
      this.formGroup.page = 1;
      ContactService.getGroups(this.formGroup).then(
        (response) => {
          this.fetchedGroups = true;
          this.groups = response.data;
          result = this.groups.filter((item) => word.toLowerCase().split(' ').every((v) => item.name.toLowerCase().includes(v)));

          this.groups = result;
          this.pagesGroup = response.last_page;
        },
        (error) => {
          this.content = error;
          this.fetchedGroups = true;
        },
      );
    },
    getContacts(page) {
      this.formContact.page = page;
      this.currentPage = page;
      this.fetchedContacts = false;
      ContactService.getContacts(this.formContact).then(
        (response) => {
          this.fetchedContacts = true;
          this.contacts = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
          this.fetchedContacts = true;
        },
      );
    },
    applyGroups() {
      //this.form.type = 'groups';
      this.form.contacts = [];
      this.groupsToAdd.forEach((group) => {
        if (!this.selectedGroups.includes(group)) {
          // this.selectedGroups.push(group);
          // this.form.groups.push(group.id);
          this.form.tags.push({
            text: group.name,
            id: group.id,
            type: 'group',
          });
        }
      });
      this.dragEvent();
    },
    applyContacts() {
      //this.form.type = 'contacts';
      this.form.contacts = [];
      this.contactsToAdd.forEach((contact) => {
        if (!this.selectedContacts.includes(contact)) {
          // this.selectedContacts.push(contact);
          // this.form.contacts.push(contact.id);
          this.form.tags.push({
            text: contact.mobile_number,
            id: contact.id,
            type: 'contact',
          });
        }
      });
      this.dragEvent();
    },
    changeCodeText(ev) {
      console.log(ev.target.value);
      this.form.template.button_parameter = this.removeAcento(ev.target.value);
      console.log(this.removeAcento(ev.target.value));
      this.$forceUpdate();
    },
    removeAcento(text) {
      text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
      text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
      text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
      text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
      text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
      text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
      return text;
    },
    getGroups(page) {
      this.formGroup.page = page;
      this.currentPageGroup = page;
      this.fetchedGroups = false;
      ContactService.getGroups(this.formGroup).then(
        (response) => {
          this.fetchedGroups = true;
          this.groups = response.data;
          this.pagesGroup = response.last_page;
        },
        (error) => {
          this.fetchedGroups = true;
          this.content = error;
        },
      );
    },
    initials(firstname, lastname) {
      (firstname === null ? firstname = '' : firstname); // Avoiding displaying 'null' string
      (lastname === null ? lastname = '' : lastname); // Avoiding displaying 'null' string
      const emojiRegex = /\p{Emoji}/u;
      console.log('Firstname: ', firstname, '\nLastname: ', lastname);
      let fullname = '';
      if (firstname) {
        firstname = !firstname.match(emojiRegex) ? firstname.charAt(0).toUpperCase() : firstname.replace(emojiRegex, '').charAt(0).toUpperCase();
      }
      if (lastname) {
        lastname = !lastname.match(emojiRegex) ? lastname.charAt(0).toUpperCase() : lastname.replace(emojiRegex, '').charAt(0).toUpperCase();
      }
      fullname = firstname + lastname;

      return fullname;
    },
    getWhatsNumbers() {
      this.whatsNumbers = [];
      webApi.get('whatsapp/new/numbers').then((response) => {
        console.log('WhatsNumbers', this.whatsNumbers);
        console.log(response);
        this.whatsNumber = response.data[0];
        response.data.forEach((data) => {
          this.whatsNumbers.push(data);
        });

        const wai = this.whatsNumber.whatsapp_identification ?? this.whatsNumber.phone_number;

        this.whatsapp_identification = wai;

        if (this.lastWAI) this.lastWAI = wai;
        console.log(this.whatsNumber.phone_number);
        this.fetchTemplates(1);
      }, (error) => {
        this.content = error;
        this.flowLoading = false;
      });
    },
    changeNumber() {
      console.log(this.lastWAI);
      console.log(this.whatsNumbers);
      this.whatsapp_identification = this.whatsNumber.whatsapp_identification ?? this.whatsNumber.phone_number;
      setTimeout(() => this.fetchTemplates(1), 100);
    },
    fetchTemplates(page) {
      this.fetchedTemplates = false;
      this.templatePage = page;
      this.templates = [];
      WhatsAppService.getNewTemplates(this.whatsapp_identification, {
        params: { page, search: this.searchQuery },
      }).then((response) => {
        console.log('Templates');
        if (response && response.data && response.data.content) {
          response.data.content.forEach((template) => {
            if (template.status === 'APPROVED') {
              this.templates.push(template);
            }
          });
          console.log('Search temp: ', this.templates);
          this.originalTemplates = this.templates;
        }
        this.templatePages = response.data.totalPages;
        this.fetchedTemplates = true;
      }, (error) => {
        this.content = error;
      }).finally(() => {
        this.fetchedTemplates = true;
      });
    },
    callCrop(maxWidth, maxHeight) {
      this.$root.$emit('show.modal', 'm-crop');
      this.$store.dispatch('crop/config', {
        maxWidth,
        maxHeight,
        viewMode: 1,
        aspectRatio: 1.91,
        minSize: true,
        infoSize: '1080x566',
      });
    },
    upload(type) {
      const form = new FormData();
      console.log(type);
      let source;
      console.log(this.form.template);
      if (type === 'IMAGE') {
        source = this.form.template.headerImage;
      } else if (type === 'VIDEO') {
        source = this.form.template.headerVideo;
      } else {
        source = this.form.template.headerDocument;
      }

      if (source === undefined) {
        this.$toast.show({
          title: 'Erro',
          content: 'Você precisa informar um arquivo de cabeçalho para o template.',
          type: 'danger',
        });
        return;
      }
      form.append('files[]', source.file);
      form.append('role', 'public');
      form.append('folder', 'whatsapp');
      form.append('account_id', this.$store.state.account.id);

      if (type === 'IMAGE') {
        const crop = JSON.stringify([
          {
            x: source.x,
            y: source.y,
            w: source.width,
            h: source.height,
          },
        ]);

        form.append('crop', crop);
      }
      this.isSending = true;
      BucketService.upload(form, true, (progress) => {
        this.uploadProgress = progress;
      })
        .then((responses) => {
          console.log(responses);
          const url = `${responses.data[0].url}.${responses.data[0].extension}`;
          console.log('BucketService', url);
          this.form.template.image = url;
          this.send();
        })
        .catch((error) => {
          this.content = error;
          this.isSending = false;
          console.error(error);
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    crop(data) {
      this.cropActive = data;
      this.$root.$emit('show.modal', 'request-modal');
      if (data) {
        this.file = data;
        this.form.template.header.example_parameter = data;
        this.form.template.headerImage = data;
        this.$root.$emit('whatsapp.changeTemplate', this.form.template);
      }
      console.log(this.form.template);
    },
    fetchClient() {
      Promise.all([WhatsAppService.getClient()])
        .then(
          (responses) => {
            console.log(responses);
            this.client = responses[0].data;
            this.fetched = true;
            this.form.client_id = this.client.id;
            console.log('Chamando antes...');
            this.$root.$emit('templatenew');
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    selectedGroupsChange(param) {
      param.selectedGroups.forEach((group) => {
        if (!this.selectedGroups.includes(group.id)) {
          this.groups.push(group);
          this.selectedGroups.push(group.id);
        }
      });
    },
    selectedContactsChange(param) {
      param.selectedContacts.forEach((contact) => {
        if (!this.selectedContacts.includes(contact.id)) {
          this.contacts.push(contact);
          this.selectedContacts.push(contact.id);
        }
      });
    },
    selectedFilesChange(param) {
      param.selectedFiles.forEach((file) => {
        if (!this.selectedFiles.includes(file.id)) {
          this.files.push(file);
          this.selectedFiles.push(file.id);
        }
      });
    },
    changeType(type) {
      if (type === 'contacts' || type === 'groups') {
        this.vars = [
          { name: this.$t('generic-str.lbl-first-name'), value: 'first_name' },
          { name: this.$t('generic-str.lbl-last-name'), value: 'last_name' },
          { name: this.$t('generic-str.lbl-cellphone'), value: 'mobile_number' },
          { name: 'Email', value: 'email' },
        ];
      } else if (type === 'files') {
        this.vars = this.fileVariables;
      } else {
        this.vars = [];
      }
      this.form.type = type;
      this.submited = false;
    },
    openFileUpload() {
      this.$refs.uploadedFile.click();
    },
    uploadFile(event) {
      this.changeProgress(0);
      const self = this;
      const reader = new FileReader();
      this.importing = true;
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append('file', file);
      this.form.file = file.name;
      this.form.type = 'files';

      reader.onload = () => {
        const variables = reader.result
          .split('\n')
          .shift()
          .replace('\r', '')
          .split(';'); // first line
        variables.forEach((variable) => {
          self.fileVariables.push({
            value: variable,
            name: variable,
          });
        });
      };
      reader.readAsText(file, 'UTF-8');

      const config = {
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          this.changeProgress(this.uploadProgress);
        },
      };

      axios()
        .post('files', formData, config)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.success'),
              type: 'success',
            });
          },
          (error) => {
            self.form.file = '';
            self.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          setTimeout(() => {
            this.importing = false;
          }, 2000);
          this.uploadProgress = 0;
        });
    },
    addVarParams(variable, i) {
      console.log(this.form.params);
      this.form.params[i].text = `${this.form.params[i].text}{${variable.value}}`;
      this.$forceUpdate();
    },
    removeGroup(i) {
      this.selectedGroups.splice(i, 1);
      this.groupsToAdd.splice(i, 1);
    },
    removeContact(i) {
      this.selectedContacts.splice(i, 1);
      this.contactsToAdd.splice(i, 1);
    },
    removeFile(i) {
      this.files.splice(i, 1);
      this.selectedFiles.splice(i, 1);
    },
    addVar(entity) {
      this.addVars = false;
      this.form.body = this.form.body.concat(` {${entity}} `);
      this.hasVar = true;
      this.countSegments(15);
    },
    smsTextChange() {
      let contVar = false;
      if (this.type === 'files') {
        this.fileVariables.forEach((element) => {
          if (this.form.body.includes(element.value)) {
            contVar = true;
          }
          this.hasVar = contVar;
        });
      } else {
        this.vars.forEach((element) => {
          if (this.form.body.includes(element.value)) {
            contVar = true;
          }
          this.hasVar = contVar;
        });
      }
    },
    showAddVar() {
      this.addVars = true;
    },
    hideAddVar() {
      this.addVars = false;
    },
    countSegments() {
      this.length = this.form.body.length;
    },
    insertTemplate(param) {
      const template = JSON.parse(param);
      console.log('insertTemplate', template, param);

      this.preview = template;

      const data = [];
      const countVar = (template.body?.text?.match(/\{\{(.*?)\}\}/g) ?? []).length;

      let last = 1;
      let max = 0;
      if (template.body.text) {
        template.body.text.replace(/\{\{[^\s]+?\}\}/g, (param) => {
          let newValue;

          param = param.slice(2, -2);

          if (param <= last && param !== '0') {
            newValue = param;
          } else {
            last += 1;
            newValue = last;
          }

          // if (param < last || param === '0') {
          //   newValue = last;
          // } else {
          //   newValue = param;
          // }

          // last += 1;

          if (newValue > max) max = parseInt(newValue, 10);

          return `{{${newValue}}}`;
        });
      }

      this.form.template_name = template.name;
      if (max && this.form.body_parameters.length !== max) {
        this.form.body_parameters = new Array(max);
      }
      // this.form.body_parameters = [...new Array(countVar)].map((x) => '');
      this.form.business_id = template.phoneIdentification;

      console.log('countVar', countVar, template.name, this.form.body_parameters);

      // button_parameter: null,
      // client_number: '',
      // header_parameter: null,
      // header_type: null,
      // language_code: 'pt_BR',
      // name: '',
      // business_id: '<phone_identification>',
    },
    requestSend() {
      console.log('File', typeof this.form.file);
      console.log('Tags', this.form.tags.length);
      if (this.form.datetime) {
        this.form.scheduled_to = moment(this.form.datetime, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
      }

      if (this.form.tags.length == 0 && (this.form.file == null || this.form.file == '')) {
        this.$toast.show({
          title: 'Erro',
          content: 'Por favor, informe o destinatário.',
          type: 'danger',
        });
        return;
      }

      if (this.form.template == null || this.form.template.business_id == undefined) {
        this.$toast.show({
          title: 'Erro',
          content: 'Por favor, informe um template antes de enviar.',
          type: 'danger',
        });
        return;
      }

      if (this.form.template.type == 'CUPOM') {
        if (this.form.template.button_parameter == '' || this.form.template.button_parameter == null) {
          this.$toast.show({
            title: 'Erro',
            content: 'Por favor, informe um código para o cupom.',
            type: 'danger',
          });
          return;
        }
      }

      if (this.form.template.type == 'AUTH') {
        if (this.form.template.button_parameter == '' || this.form.template.button_parameter == null) {
          this.$toast.show({
            title: 'Erro',
            content: 'Por favor, informe um código.',
            type: 'danger',
          });
          return;
        } else {
          this.form.body_parameters = [this.form.template.button_parameter];
        }
      }

      if (this.form.body_parameters.length > 0) {
        let isOk = true;
        console.log('this.form.body_parameters', this.form.body_parameters);
        this.form.body_parameters.forEach((param) => {
          if ((param === '' || param == undefined || param == null || !param) || (param.length == 0)) {
            isOk = false;
          }
        });
        if (!isOk) {
          this.$toast.show({
            title: 'Erro',
            content: 'Por favor, preencha todos os parâmetros.',
            type: 'danger',
          });
          return;
        }
      }

      this.form.body = this.form.template.body.text;
      if (this.whatsNumber.name) {
        this.form.platform = 'meta';
      } else {
        this.form.platform = '360dialog';
      }
      const header = this.form.template.header;
      const type = header ? header.type : false;
      Swal.fire({
        title: 'Enviar Mensagens',
        text: this.$t('warnings.send-component.send-msg-confirm'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (!result.value) return;
        if (type === 'TEXT' || !type) {
          this.send();
        } else {
          this.form.template.image = this.url;
          this.send();
        }
      });
    },
    send() {
      this.isSending = true;
      if (this.form.template.business_id) {
        switch (this.form.type) {
          case 'simple':
            this.sendCampaing();
            break;
          case 'contacts':
            this.sendCampaing();
            break;
          case 'files':
            this.sendCampaing();
            break;
          default:
            this.sendCampaing();
            break;
        }
      }
    },
    changeCategory(category) {
      this.templateCategory = category === this.templateCategory ? '' : category;
      this.templates = this.originalTemplates;
      if (this.templateCategory !== '') {
        this.templates = this.originalTemplates.filter(template => (template.category === category));
        this.$forceUpdate();
      }
    },
    sendCampaing() {
      switch (this.form.template.type) {
        case 'CATALOG':
          let temp = [];
          console.log('button_parameter', this.form.template.button_parameter)
          this.form.template.button_parameter.forEach((text) => {
            temp.push(text.text);
          });
          this.form.template.button_parameter = temp.join(';');
          break;
        case 'MPM':
          this.form.template.button_index = 0;
          this.form.template.sections = this.sections;
          this.form.template.button_parameter = this.form.template.button_parameter;
          break;
        case 'OFFER':
          this.form.template.button_parameter = this.form.template.button_parameter;
          this.form.template.expiration_time_ms = this.form.template.expiration_time_ms * 1000;
          break;
        case 'CAROUSEL':
          let cards = [];
          let cardIndex = 0;
          this.form.template.carousel.content.cards.forEach((card) => {
            let bodyParameters = [];
            if (card.components[1].example) {
              card.components[1].example.body_text[0].forEach(element => {
                bodyParameters.push({
                  type: 'TEXT',
                  text: element,
                })
              });
            }
            cards.push({
              card_index: cardIndex,
              components: [
                {
                  type: 'HEADER',
                  parameters: [
                    {
                      type: 'IMAGE',
                      image: {
                        link: card.components[0].example.header_handle[0]
                      }
                    }
                  ],
                },
                {
                  type: 'BODY',
                  parameters: bodyParameters,
                },
                {
                  type: 'BUTTON',
                  sub_type: 'QUICK_REPLY',
                  index: 0,
                  parameters: [
                    {
                      type: 'PAYLOAD',
                      payload: card.components[2].buttons[0].text,
                    },
                  ],
                },
                {
                  type: 'BUTTON',
                  sub_type: 'URL',
                  index: 1,
                  parameters: [
                    {
                      type: 'PAYLOAD',
                      payload: card.components[2].buttons[1].url,
                    },
                  ],
                }
              ]
            });
            cardIndex++;
          });
          this.form.template.cards = JSON.stringify(cards);
          break;
        default:
          break;
      }
      console.log('Template send', this.form.template);
      webApi.post('whatsapp/new/campaign', this.form).then((response) => {
        this.$toast.show({
          title: 'Sucesso',
          content: 'Mensagem enviada com sucesso',
          type: 'success',
        });
        this.isSending = false;
        this.$router.push({
          name: 'SuccessWhatsAppMeta',
          params: { data: response.data },
        });
      }).catch((err) => {
        this.$toast.show({
          title: 'Erro',
          content: String(err),
          type: 'danger',
        });
        this.isSending = false;
      });
    },
    sendRequest() {
      if (this.form.template.type === 'CATALOG') {
        let temp = [];
        console.log('button_parameter', this.form.template.button_parameter)
        this.form.template.button_parameter.forEach((text) => {
          temp.push(text.text);
        });
        this.form.template.button_parameter = temp.join(';');
      }
      if (this.form.template.type === 'MPM') {
        this.form.template.sections = this.sections;
        this.form.template.button_parameter = this.form.template.button_parameter;
      }
      Swal.fire({
        title: 'Enviar Mensagens',
        text: this.$t('warnings.send-component.send-msg-confirm'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (!result.value) return;

        webApi.post('whatsapp/new/send', this.form).then((response) => {
          this.$toast.show({
            title: 'Sucesso',
            content: String(response),
            type: 'success',
          });
          this.$router.push({
            name: 'SuccessWhatsAppMeta',
            params: { data: response.data },
          });
        }).catch((err) => {
          this.$toast.show({
            title: 'Erro',
            content: String(err),
            type: 'danger',
          });
        });
      });
    },
    sendData() {
      this.isSending = true;
      console.log(JSON.stringify(this.form));
      // WhatsAppService.sendText(this.form)
      //   .then(
      //     (send) => {
      //       console.log(JSON.stringify(send));
      //       if (this.form.type === 'simple') {
      //         this.$router.push({
      //           name: 'SuccessWhatsApp',
      //           params: { sms: send.data },
      //         });
      //       } else {
      //         this.$router.push('/whatsapp/campaigns');
      //       }
      //       this.$toast.show({
      //         title: this.$t('generic-str.success'),
      //         content: this.$t('warnings.send-component.msg-registered'),
      //         type: 'success',
      //       });
      //       this.isSending = false;
      //     },
      //     (error) => {
      //       this.$toast.show({
      //         title: this.$t('sms.infos.filters.status.lbl-error'),
      //         content: error.response.data.message,
      //         type: 'danger',
      //       });
      //       this.isSending = false;
      //     },
      //   )
      //   .catch((error) => {
      //     console.log(error.response);
      //     this.isSending = false;
      //   });
    },
    showTemplate(template) {
      template.show = true;
      this.$forceUpdate();
    },
    changeDate(date) {
      console.log(date);
    },
    changeTemplate(template) {
      this.form.body_parameters = [];
      const countVar = (template.body?.text?.match(/\{\{(.*?)\}\}/g) ?? []).length;
      let last = 1;
      let max = 0;
      template.type = 'DEFAULT';
      if (template.buttons) {
        if (template.buttons.type === 'CATALOG') {
          template.button_index = 0;
          template.type = 'CATALOG';
          template.button_parameter = [];
        }
        if (template.buttons.type === 'OTP') {
          template.button_index = 0;
          template.type = 'AUTH';
          template.button_parameter = '';
          template.body.text = 'Seu código de verificação é';
        }
        if (template.buttons.type === 'COPY_CODE') {
          template.button_index = 0;
          template.type = 'CUPOM';
          template.button_parameter = '';
        }
        if (template.buttons.type == 'MPM') {
          template.type = 'MPM';
        }
        if (template.limited != undefined) {
          template.type = 'OFFER';
          template.expiration_time_ms = 1;
        }
      } else {
        template.buttons = {};
      }

      if (template.carousel) {
        template.carousel.content = JSON.parse(template.carousel.content);
        template.type = 'CAROUSEL';
      }
      console.log('Final template', template.carousel);
      this.$root.$emit('whatsapp.changeTemplate', template);

      // if (body.text === this.lastBody) return;
      if (template.body.text) {
        template.body.text.replace(/\{\{[^\s]+?\}\}/g, (param) => {
          let newValue;

          param = param.slice(2, -2);

          if (param <= last && param !== '0') {
            newValue = param;
          } else {
            last += 1;
            newValue = last;
          }

          if (newValue > max) max = parseInt(newValue, 10);

          return `{{${newValue}}}`;
        });
      }
      if (max && this.form.body_parameters.length !== max) {
        this.form.body_parameters = new Array(max);
      }
      //this.form.body_parameters = [...new Array(countVar)].map((x) => '');
      this.form.business_id = template.phoneIdentification;

      console.log('countVar', countVar, template.name, this.form.body_parameters);
      console.log(this.form.template);
      console.log('Template', template);
    },
    hideTemplate(template) {
      template.show = false;
      this.$forceUpdate();
    },
    sendMedia() {
      if (
        this.file.file !== null &&
        this.file.file !== undefined
      ) {
        const formData = new FormData();
        const filesCrop = [];
        filesCrop.push({
          x: this.file.x,
          y: this.file.y,
          w: this.file.width,
          h: this.file.height,
        });
        formData.append('files[]', this.file.file);
        formData.append('role', 'public');
        formData.append('folder', 'rcs');
        formData.append('crop', JSON.stringify(filesCrop));
        this.isSending = true;
        Promise.all([
          BucketService.upload(
            formData,
            true,
            this.callBackProgress,
          ),
        ])
          .then(
            (responses) => {
              console.log(responses);
              console.log(responses[0].data.url);
              this.form.template.image = responses[0].data[0].url;
              this.sendData();
            },
            (error) => {
              this.content = error;
              this.isSending = false;
              console.log(error);
            },
          )
          .finally(() => {
            this.isSending = false;
          });
      } else {
        this.form.template.image = this.form.template.header.example.header_handle[0];
        this.sendData();
      }
    },
    callBackProgress(progress) {
      this.uploadProgress = progress;
    },
    success() {
      this.$toast.show({
        title: 'WhatsApp',
        content: 'Mensagem enviada',
        type: 'success',
      });
    },
    chooseFile() {
      this.$refs.currentFile.click();
    },
    chooseFilePdf() {
      this.$refs.currentFilePdf.click();
    },
    getLocalFile(type) {
      console.log('getLocalFile:', this.$refs.currentFile);

      const input = this.$refs.currentFile;

      if (!input) return;

      const file = input.files[0];

      if (type === 'VIDEO') {
        this.form.template.header.example_parameter = URL.createObjectURL(file);
        this.form.template.headerVideo = {
          file,
          url: URL.createObjectURL(file),
        };
      } else if (type === 'DOCUMENT') {
        this.form.template.header.example_parameter = URL.createObjectURL(file);
        this.form.template.headerDocument = {
          file,
          url: URL.createObjectURL(file),
        };
      }
      this.$root.$emit('whatsapp.changeTemplate', this.form.template);
      this.$forceUpdate();
      console.log(this.form.template);
    },
    getLocalFilePdf(type) {
      console.log('getLocalFile:', this.$refs.currentFilePdf);

      const input = this.$refs.currentFilePdf;

      if (!input) return;

      const file = input.files[0];

      if (type === 'VIDEO') {
        this.form.template.header.example_parameter = URL.createObjectURL(file);
        this.form.template.headerVideo = {
          file,
          url: URL.createObjectURL(file),
        };
      } else if (type === 'DOCUMENT') {
        this.form.template.header.example_parameter = URL.createObjectURL(file);
        this.form.template.headerDocument = {
          file,
          url: URL.createObjectURL(file),
        };
      }
      this.$root.$emit('whatsapp.changeTemplate', this.form.template);
      this.$forceUpdate();
      console.log(this.form.template);
    },
  },
  mounted() {
    /* eslint-disable */
    this.$root.$on('groups.addonsend', this.selectedGroupsChange);
    this.$root.$on('contacts.addonsend', this.selectedContactsChange);
    this.$root.$on('files.addonsend', this.selectedFilesChange);
    this.$root.$on('call.crop', this.crop);
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .preview {
//   min-height: 400px;
//   height: 70%;
// }

textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

textarea:focus+.textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}

.input-date {
  margin-top: 15px !important;
}

.form-control.invalid {
  border-color: #ff5c75;
}

.actions-var {
  position: relative !important;
}

.emoji-color {
  color: #a6a6a6 !important;
}

.send-group-card {
  box-shadow: none;
}

.send-group-card .table-placeholder i,
h5 {
  color: #b5b5b5;
}

.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}

.actions-icon {
  font-size: 14px !important;
}

.actions-var a {
  cursor: pointer;
}

.custom-control-inline-send {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.label-send-check {
  margin-left: 10px;
  margin-top: 2px;
}

.ul-list-groups {
  margin: 0px;
  padding: 0px;
}

.ul-list-groups li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.var-insede-input {
  margin-left: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.var-insede-input i {
  font-size: 15px !important;
}

/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}

.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}

.nav.nav-tabs .nav-item .nav-link.active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
  color: var(--clr-light-2);
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

/* .send-opt li .control-group .control_indicator {
  float: left;
  margin-right: 10px;
} */

.send-opt {
  color: var(--gray-font-color);
}

.send-opt .control-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.send-opt .control span {
  margin-top: 5px;
}

.card-footer-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem 0;
  border-top: 2px solid #00000029;
}

.right-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: fit-content;
  margin-left: auto;
}

.right-items::v-deep .dropdown-menu .dropdown-item {
  color: var(--clr-yup-purple);
}

.card-footer-wrapper i.title,
.card-footer-wrapper span.title {
  font-size: 1.4rem;
  transition: 0.5s;
  padding: 9px;
}

.card-footer-wrapper span.attach {
  transform: rotateY(180deg);
}

.card-footer-wrapper i.title:hover,
.card-footer-wrapper span.title:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.recipient-wrapper,
#recipients-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

#recipients-list li {
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid #f7f7f7;
}

.darkmode #recipients-list li {
  border-color: var(--background-3);
  background-color: var(--background-2);
}

#recipients-list li .delete-item {
  font-size: 0.7rem;
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}

#recipients-list li .delete-item:hover {
  background-color: rgba(210, 221, 234, 0.5);
}

.darkmode #recipients-list li .delete-item:hover {
  background-color: var(--background-3);
}

/* .dropup::v-deep ul.dropdown-menu.show {
  top: -180% !important;
} */

.dropup::v-deep ul.dropdown-menu {
  width: fit-content;
}

.dropup::v-deep ul.dropdown-menu a.dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.template-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.template-wrapper label {
  padding-right: 3rem;
}

.form-floating.floating-transparent textarea {
  border-color: transparent !important;
  /* border-bottom: 2px solid #00000029 !important; */
  padding-left: 0;
  margin-left: 0.8rem;
}

.template-style {
  font-size: 0.8rem;
}

.v-select::v-deep>div:first-child,
.campaign-input,
.form-file {
  min-width: 320px;
  max-width: 320px;
}

.sender-group,
.template-wrapper {
  margin-bottom: 1.5rem;
}

.campaign-group {
  margin-bottom: 3rem;
}

::v-deep label {
  color: var(--gray-font-color-2);
  font-weight: 400;
}

::v-deep .vs__search {
  position: absolute !important;
}

.add-contacts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.btn_add {
  width: fit-content;
  height: auto;
  padding: 0;
  margin-left: auto;
}

hr {
  width: 100%;
  border-top: 2px solid #00000029;
}

.btn-import {
  width: fit-content;
  height: auto;
  padding: 0;
  margin-left: 1.25rem;
}

.btn-import span {
  color: var(--gray-font-color);
  font-weight: 300;
}

.archive-opt {}

.darkmode .form-floating.floating-transparent textarea {
  background-color: var(--background-2);
}

.darkmode .nav-tabs .nav-link.active {
  background-color: var(--background-3);
}

.darkmode .nav.nav-tabs .nav-item .nav-link:hover,
.darkmode .nav.nav-tabs .nav-item .nav-link:hover i {
  color: #fff;
}

.calendar_form {
  position: absolute;
  right: 150px !important;
  width: 150px !important;

  input.input-date {
    opacity: 0;
  }
}

.calendar_form:hover~.calendar_icon,
.calendar_form:focus-within~.calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 25px;
}

.card {
  border: 1px solid #E5E5E5;
  box-shadow: none;
}

.card-header {
  padding: 10px 18px !important;
  background: #F2F2F2;
  border-radius: 8px 8px var(8px, 0px) var(8px, 0px);
}

.card-hours-span {
  color: #AAA;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  width: 72%;
}

.card-header-right {
  display: flex;
  width: 25%;
  flex-direction: row;
  cursor: pointer;
}

.card-header-right:hover span {
  color: #312b2b;
}

.card-header-right:hover #light-svg {
  fill: #312b2b;
}

.card-header-right span {
  color: #595959;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  padding-top: 2px;
  margin-left: 5px;
}

.info-icons {
  color: #AAAAAA;
  font-size: 24px;
  margin-top: 5px;
  margin-left: 15px;
}

.form-control-send {
  padding: 10px 8px !important;
  border-radius: 8px !important;
  border: 2px solid #E5E5E5 !important;
}

.list-numbers {
  list-style: none;
  margin: 0px !important;
  padding: 0px !important;
}

.list-numbers li {
  color: #595959;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border-radius: 100px;
  padding: 4px 10px;
  border: 2px solid #E5E5E5;
  float: left;
  margin-right: 24px;
  width: 41%;
}

.list-numbers li div .span-close {
  font-size: 15px;
  cursor: pointer;
  display: inline-block;
}

.no-margin {
  margin: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

.icon-sends {
  padding-left: 10px !important;
}

.icon-sends span {
  margin-top: 4px;
  color: #697077;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
  display: inline-block;
}

.icon-sends span:hover {
  color: #3e4042;
}

.add-circle {
  color: #6B23DC !important;
}

.divider-form {
  width: 100%;
  height: 4px;
  background: rgba(107, 35, 220, 0.24);
  margin-left: 10px;
  margin-right: 10px;
}

.text-right {
  text-align: right !important;
}

.send-button {
  padding: 6px 24px;
  border-radius: 8px;
  background: #6B23DC;
  color: #fff;
  border: none;
  margin-top: 15px;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  margin-left: 22px;
}

.send-button-outline {
  background: #fff;
  color: #6B23DC;
  border: 1px solid #6B23DC;
}

.send-button:hover {
  background: #732be6;
}

.send-button-outline:hover {
  background: #e4d8f7;
}

::v-deep #sidebar-right-group {
  background-color: var(--background-2) !important;
  width: 525px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}

::v-deep #sidebar-right-contact {
  background-color: var(--background-2) !important;
  width: 625px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}

.btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: min-content;
  background-color: #6B23DC;
  color: #FFF;
  font-weight: 400;
  border-radius: 8px;
  font-size: 1rem;
}

.search-input {
  width: 100%;
  margin-bottom: 22px;
}

.search-input input {
  height: 50px;
}

.search-input span {
  position: absolute;
  top: 12px;
  right: 10px;
}

.search-input span:hover {
  color: #ccc;
}

.header-options {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 22px;
}

.header-options-title {
  width: 30%;
}

.header-options-title span {
  color: #0D0519;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.header-options-buttons {
  display: flex;
}

.header-options-buttons span {
  color: #6B23DC;
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 4px;
  background: rgba(107, 35, 220, 0.08);
  border-radius: 8px;
  margin-right: 16px;
}

.header-options-buttons h4 {
  color: #6B23DC;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 5px;
}

.row-list {
  display: flex;
  width: 100%;
  margin: 0px;
  padding: 16px;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  margin-bottom: 10px;
}

.row-list .material-symbols-outlined {
  font-size: 21px;
  margin-right: 8px;
}

.row-list span {
  color: #697077;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.flex {
  display: flex !important;
}

.modal-footer {
  margin-top: 40px;
  margin-bottom: 40px;
}

::v-deep .b-sidebar-modal .b-sidebar {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.contact-name {
  margin-top: 3px;
}

.contact-phone {
  margin-top: 5px;
  display: inline-block;
}

.contact-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
}

::v-deep #sidebar-right-template {
  background-color: var(--background-2) !important;
  width: 525px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}

.header-options-buttons-template {
  color: #697077 !important;
  width: 54% !important;
}

.header-options-buttons-template h4 {
  margin-right: 16px !important;
}

.preview-wrapper {
  /* position: sticky;
  top: 100px; */
  display: flex;
  align-items: center;
  height: 472px;
}

.card-preview {
  width: 60%;
}

.preview-message-btns {
  border-top: 1px solid #dadde1;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  color: #00a5f4;
  font-size: 14px;
  line-height: 20px;

  .btn {
    &:nth-child(1) {
      float: left;
      width: 48%;
    }

    &:nth-child(2) {
      float: right;
      width: 48%;
    }

    &.max {
      width: 100%;
    }
  }
}

.preview-message-btns i {
  color: #00a5f4;
}

.preview-message-btns span {
  display: flex;
  margin-bottom: 8px;
  justify-content: center;
  gap: 1rem;
  margin-top: .725rem;
}

.c-pointer {
  cursor: pointer !important;
}

.media {
  video {
    max-height: 220px;
  }
}

.img-wrapper {
  display: flex;
  justify-content: center;
}

.card-options {
  position: absolute;
  top: -70px;
  right: 0;
  width: 330.633px;
  // min-width: 330.633px;
  // max-width: 330.633px;
  // min-height: 200px;
  background-color: #00000038;
  backdrop-filter: blur(10px);
  z-index: 5;

  .card-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #000000b2;
    color: #fff;
    padding: .285rem 1rem;

    button {
      height: fit-content;
    }
  }

  .card-body {
    background-color: #0000009c;
  }

  .form-control {
    background-color: #000000a1 !important;
    border-color: #000 !important;
    color: #fff;
  }

  .material-icons-outlined.chevron {
    transition: .5s;
    color: #fff;
  }

  .material-icons-outlined.chevron.open {
    transform: rotate(-180deg);
  }
}

.card-template {
  border-radius: 8px;
  background: #F7F7F7;
  margin-top: 15px;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.row-templates {
  margin: 0px;
  padding: 0px;
  border-radius: 8px;
  background: #F7F7F7;
  width: 100%;
  margin-top: 10px;
}

.card-preview {
  width: 60%;
}

.card-text {
  font-size: 8.85px;
}

.btn-link-preview .btn.max {
  font-size: 10px;
}

.icon-show-hide {
  font-size: 24px;
  color: #AAA;
}

.row-template-list {
  padding: 10px;
}

.row-template-list .col-sm-1 {
  padding-top: 3px;
}

.row-template-list .col-sm-9 span {
  margin-top: 4px;
}

.row-template-list .col-sm-2 {
  text-align: right;
  padding-right: 0px;
}

.row-template-list .col-sm-2 span {
  font-size: 24px;
  color: #AAAAAA;
  margin-top: 2px;
}

::v-deep #sidebar-right-import {
  background-color: var(--background-2) !important;
  width: 525px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}

.drop-item-upload {
  width: 100%;
  height: 350px;
  border: dashed 2px #697077;
  border-radius: 8px;
  margin-top: 18px;
  text-align: center;
}

.drop-item-upload h3 {
  color: #595959;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}

.drop-item-upload button {
  border: 1px solid #6B23DC;
  background: #fff;
  padding: 6px 24px 6px 24px;
  border-radius: 8px;
  color: #6B23DC;
  margin-top: 15px;
  font-size: 14px;
}

.drop-item-upload img {
  margin-top: 60px;
}

.drop-item-upload-p {
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
  color: #595959;
}

.calendar_form {
  position: absolute;
  right: 146px;
  width: 25px;

  input.input-date {
    opacity: 0;
  }
}

.calendar_form:hover~.calendar_icon,
.calendar_form:focus-within~.calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 100%;
}

.btn-tags {
  border: 1px solid var(--gray-font-color-3);
  border-radius: 25px;
  font-weight: 400;
  color: var(--gray-font-color-2);
}

/* Tags wrapper */
.tags-wrapper {
  position: relative;
  overflow-x: hidden;
  max-width: 85%;
  background-color: var(--background);
  border-radius: 13px;
  margin-left: -30px;

  .icon {
    position: absolute;
    top: 0;
    height: 100%;
    width: 120px;
    display: flex;
    align-items: center;

    .material-symbols-outlined {
      width: 40px;
      height: 40px;
      cursor: pointer;
      font-size: 1.2rem;
      text-align: center;
      line-height: 40px;
      border-radius: 50%;
      background-color: var(--background);
      border: 1px solid var(--gray-font-color-3);

      &:hover {
        background: #efedfb;
      }
    }

    &:first-child {
      left: 0;
      display: none;
      background: linear-gradient(90deg, var(--background) 70%, transparent);

      & .material-symbols-outlined {
        margin-left: 30px;
      }
    }

    &:last-child {
      right: 0;
      display: none;
      justify-content: flex-end;
      background: linear-gradient(-90deg, var(--background) 70%, transparent);

      & .material-symbols-outlined {
        margin-right: 30px;
      }
    }
  }

  .tabs-box {
    display: flex;
    gap: 12px;
    list-style: none;
    margin-bottom: 0;
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding-left: 0;

    &.dragging {
      scroll-behavior: auto;
      cursor: grab;

      & .tab {
        user-select: none;
        pointer-events: none;
      }
    }
  }

  .span-close {
    font-size: 20px;
    margin-top: 1px;
  }

  .tab {
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap;
    background: var(--background);
    border-radius: 30px;
    border: 1px solid var(--gray-font-color-3);
    padding: 5px 12px;
    color: #595959;
    border: 2px solid #E5E5E5;
    display: flex;

    &.active {
      background-color: var(--clr-yup-purple);
      color: #FFF;
      border-color: transparent;
    }

    &.btn-settings {
      a {
        display: flex;
        color: var(--gray-font-color-4);
      }
    }
  }
}

.custom-control.custom-checkbox {
  margin-top: -15px;
  padding-left: 15px;
}

.fallback-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #fff;
}

.profile-photo {
  width: 32px;
  height: 32px;
  margin-right: 15px;

  figure {
    border-radius: 48px;
    background: #752de6;
    width: 32px;
    height: 32px;
    margin: 0px;
  }

  &:hover {
    color: #fc0;

    &~.profile-more-details {
      opacity: 1;
      z-index: 100;
    }
  }
}

@keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100;
  }
}

@keyframes circle-chart-appear {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.circle-chart__circle {
  animation: circle-chart-fill 2s reverse;
  /* 1 */
  transform-origin: center;
  /* 4 */
}

.circle-chart__circle--negative {
  transform: rotate(-90deg) scale(1, -1);
  /* 1, 2, 3 */
}

.circle-chart__info {
  animation: circle-chart-appear 2s forwards;
  opacity: 0;
  transform: translateY(0.3em);
}

svg g text.circle-chart__percent {
  fill: #343D47;
  font-weight: 700;
}

svg g text.circle-chart__subline {
  fill: #C2C2C2;
  margin-top: 7px;
}

#svg circle {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s linear;
  stroke: #cecece;
  stroke-width: 5px;
}

#svg #bar {
  stroke: #6B23DC;
}

#cont {
  display: block;
  height: 200px;
  width: 200px;
  margin: 0 auto;
  border-radius: 100%;
  position: relative;
  margin-top: 35px;
}

#cont:after {
  position: absolute;
  display: block;
  height: 160px;
  width: 160px;
  left: 50%;
  top: 50%;
  content: attr(data-pct)"%";
  margin-top: -80px;
  margin-left: -80px;
  border-radius: 100%;
  line-height: 160px;
  font-size: 2em;
}

.csv-file {
  width: 100%;
  padding: 10px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  margin: 0;
  margin-top: 15px;
}

.name-csv-div {
  width: 75%;
}

.name-csv-div span {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #595959;
}

.csv-progress {
  width: 100%;
  height: 8px;
  background: #6B23DC;
  border-radius: 6px;
}

.name-csv-percent {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  margin-left: 10px;
}

.card-header-left {
  width: 72%;
}

.flex-row {
  flex-direction: row;
  display: flex;
}

.card-header-photo {
  background: #003969;
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.card-header-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #0D0519;
}

.card-header-number {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  text-decoration-line: underline;
  color: #595959;
}

.card-header-number-text {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #AAA;
}

.card-header-sync {
  font-size: 16px;
  color: #697077;
  display: inline-block;
}

::v-deep #sidebar-right-numbers {
  background-color: var(--background-2) !important;
  width: 625px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}

.close-input {
  position: absolute;
  top: 8px;
  font-size: 20px;
  right: 10px;
}

@media (max-width: 1301px) {
  .card-header-left {
    width: 50%;
  }

  .card-header-right {
    width: 45%;
    padding-left: 10%;
  }
}

.info-whatsapp-button {
  display: flex;
  flex-direction: row;
}

.info-whatsapp-button .card-header-sync {
  margin-top: 4px;
}

.info-whatsapp-button .card-header-number {
  margin-left: 5px;
}

.info-var {
  color: #16CD08;
}

.info-var-tip {
  color: #6B23DC;
}

.info-var-tip-text {
  color: #AAA;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding-top: 5px;
}

.var-name-span {
  color: #AD1519;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.btn-files {
  border-left: 1px #E5E5E5 solid;
  padding-top: 5px;
  margin-top: 15px;
}

.btn-files span {
  color: #697077;
  margin-right: 15px;
  cursor: pointer;
}

.btn-files span:hover {
  color: #ccc;
}

::v-deep .tags-container {
  .ti-input {
    border: none;
    padding: 0;
  }

  .form-control-send {
    padding: 3px !important;
    height: auto !important;
  }

  .vue-tags-input {
    min-height: 100px;
  }
}

.card-template {
  background: #fff;
}

.row-templates {
  background: #fff;
}

::v-deep .card-template .card-preview {
  width: 90%;
  margin-top: 0;
}

::v-deep .tags-container-prod {
  .ti-input {
    border: none;
    padding: 0;
  }

  .form-control-send {
    padding: 3px !important;
    height: auto !important;
  }
}

.select-agent {
  margin-top: 10px;
  width: 58%;
  border-radius: 8px !important;
  border: 2px solid #E5E5E5 !important;
  color: #b0b0b0;
}

.m-top-10 {
  margin-top: 10px !important;
}

.session-products {
  border-radius: 8px !important;
  border: 2px solid #E5E5E5 !important;
  padding: 8px;
  padding-bottom: 0px;
}

.session-products .session-title {
  margin-bottom: 0px;
  font-weight: 300;
}

.session-products .session-arrows {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6px;
}

.session-products .session-arrows span {
  cursor: pointer;
}

.session-products .session-arrows span:hover {
  color: #6B23DC;
  background: #f5f5f5;
}

.session-products .session-arrows .btn-delete-session {
  color: #aeaeae;
  font-size: 18px;
  margin-top: 3px;
}

.form-session-title {
  margin-bottom: 10px;
}

.btn-add-session {
  color: #732be6;
  cursor: pointer;
  margin-left: 5px;
}

.btn-add-session:hover {
  color: #511fa2;
}

.m-bottom-10 {
  margin-bottom: 10px !important;
}

.session-products {
  margin-bottom: 10px !important;
}

.m-0 {
  margin: 0px !important;
}

.btn-add-product {
  display: flex;
  margin-bottom: 10px;
}

.btn-add-product span {
  font-size: 18px;
  margin-top: 2px;
}

.title-session-h5 {}

.hr-top-divider {
  border-top: 1px solid #e4e4e4
}

.accordion-wpp-template {
  margin-top: .5rem;
  margin-bottom: 2rem;
  display: flex;

  .card {
    background-color: #F9FAFB;
    border-color: #E5E5E5;
    border-width: 1px !important;
    border-style: solid;
    box-shadow: none;
    margin-bottom: 1.25rem;
    flex: 1;
    margin: 5px;

    .card-header {
      display: flex;
      height: auto;
      align-items: center;
      padding: 5px !important;
      background-color: #F2F4F7;
      border-color: #E5E5E5;
    }
  }

  .card .card-header:hover {
    background: #f5eefc;
  }

  .card .card-header.active {
    background: #752de6;
  }

  .card .card-header .material-symbols-outlined {
    font-size: 30px !important;
  }

  .card .card-header.active .material-symbols-outlined {
    color: #ffffff;
    font-size: 30px !important;
  }

  .btn-wrapper-opt {
    width: 100%;
    display: flex;
    height: fit-content;
    align-items: center;
    gap: .5rem;
    position: relative;
    z-index: 1;

    span.material-symbols-outlined {
      font-size: 45px;
      color: #667085;
      margin-right: 1rem;
    }

    .btn-template {
      height: auto;
      padding: 0;
      font-weight: 400;
      width: 100%;
      text-align: center;

      .wrapper-title {
        display: flex;
        flex-direction: column;
        text-align: start;

        p.title-opt {
          font-size: .875rem;
          margin-bottom: .25rem;
        }

        span {
          white-space: break-spaces;
          font-size: .75rem;
          color: #595959;
        }
      }
    }

    .toggle-check {
      margin-left: auto;
      position: relative;
      z-index: 0;

      .control .control_indicator {
        order: 1;
        margin-left: auto;
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
}

.w-100 {
  width: 100% !important;
}

.no-item span {
  font-size: 50px !important;
  color: #bababa;
  margin-top: 18px;
}

.no-item p {
  font-size: 18px;
  color: #bababa;
}

.p-0 {
  padding: 0px;
}
</style>
